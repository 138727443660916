<template>
  <FadeTransition>
    <div v-if="initialized" class="row justify-content-center">
      <div class="col-12" v-if="shouldShowCampaigns">
        <CampaignSetupsListHeader />
      </div>
      <div class="col-12" v-if="shouldShowCampaigns">
        <CampaignSetupsListContent v-if="!selectIsLoadingCampaignSetups" />
        <b-skeleton-table :columns="4" :rows="6" v-else />
      </div>
      <div class="col-12 col-lg-10 mt-6" v-if="!shouldShowCampaigns">
        <CampaignSetupsOffstate :company="selectActiveCompany" />
      </div>
    </div>

    <!-- loader -->
    <div v-else>
      <div class="d-flex justify-content-between mt-4">
        <b-skeleton width="150px" height="50px" />
        <b-skeleton width="150px" height="50px" />
      </div>
      <div class="d-flex mt-4">
        <div v-for="x in 5" :key="x" class="mr-2">
          <b-skeleton width="50px" height="30px" />
        </div>
      </div>
      <hr />
      <b-skeleton-table :columns="4" :rows="6" />
    </div>
  </FadeTransition>
</template>

<script>
import FadeTransition from '@/components/Transitions/FadeTransition'
import CampaignSetupsListHeader from '@/components/Modules/CampaignSetups/CampaignSetupsListHeader'
import CampaignSetupsListContent from '@/components/Modules/CampaignSetups/CampaignSetupsListContent'
import CampaignSetupsOffstate from '@/components/Modules/CampaignSetups/CampaignSetupsOffstate'
import { createNamespacedHelpers } from 'vuex'

const CampaignSetupModule = createNamespacedHelpers('campaignSetups')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'CampaignSetupsView',
  components: {
    FadeTransition,
    CampaignSetupsListHeader,
    CampaignSetupsListContent,
    CampaignSetupsOffstate,
  },
  data: () => ({
    initialized: false,
  }),
  async mounted() {
    await Promise.all([this.fetchCampaignSetups(), this.fetchTextUsage()])
    this.initialized = true
  },
  computed: {
    ...CompanyModule.mapGetters(['selectActiveCompany']),
    ...CampaignSetupModule.mapGetters(['selectCampaignSetups', 'selectIsLoadingCampaignSetups']),
    shouldShowCampaigns() {
      return (
        this.selectCampaignSetups.length &&
        (this.selectActiveCompany._id === 'ALL_COMPANIES' ||
          (this.selectActiveCompany._id !== 'ALL_COMPANIES' &&
            this.selectActiveCompany.campaignsEnabled))
      )
    },
  },
  methods: {
    ...CampaignSetupModule.mapActions(['fetchCampaignSetups', 'fetchTextUsage']),
  },
}
</script>
