<template>
  <div class="dropdown d-inline-block">
    <a class="dropdown-ellipses dropdown-toggle" data-toggle="dropdown" href="#!" role="button">
      <i class="fe fe-more-vertical"></i>
    </a>
    <div class="dropdown-menu dropdown-menu-right">
      <a
        v-for="item in optionsDropdownItems"
        :key="item.text"
        @click="item.action"
        class="dropdown-item"
        href="javascript:;"
      >
        <span :class="item.type">{{ item.text }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CampaignSetupsListRowDropdown',
  props: ['campaignSetup'],
  computed: {
    isDisabled() {
      return this.campaignSetup.status === 'disabled'
    },
    isActive() {
      return this.campaignSetup.status === 'active'
    },
    optionsDropdownItems() {
      return [
        {
          type: 'text-primary',
          text: 'Duplicate',
          action: () => {
            this.$emit('duplicate')
          },
        },
        ...(this.isDisabled
          ? [
              {
                type: 'text-success',
                text: 'Activate',
                action: () => {
                  this.$emit('toggle')
                },
              },
            ]
          : []),
        ...(this.isActive
          ? [
              {
                type: 'text-warning',
                text: 'Disable',
                action: () => {
                  this.$emit('toggle')
                },
              },
            ]
          : []),
        {
          type: 'text-danger',
          text: 'Delete',
          action: () => {
            this.$emit('delete')
          },
        },
      ]
    },
  },
}
</script>

<style scoped lang="scss"></style>
