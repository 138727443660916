<template>
  <div>
    <CampaignSetupsSummaryModal ref="campaignSetupsSummaryModal" />
    <CampaignSetupsListTable :filteredCampaignSetups="filteredCampaignSetups" @viewSummary="viewSummary" />
  </div>
</template>

<script>
import CampaignSetupsListTable from '@/components/Modules/CampaignSetups/Components/CampaignSetupsListTable'
import CampaignSetupsSummaryModal from '@/components/Modals/CampaignSetupsSummaryModal'
import { createNamespacedHelpers } from 'vuex'

const CampaignSetupsModule = createNamespacedHelpers('campaignSetups')

export default {
  name: 'CampaignSetupsListContent',
  components: {
    CampaignSetupsSummaryModal,
    CampaignSetupsListTable,
  },
  computed: {
    ...CampaignSetupsModule.mapGetters(['selectCampaignSetups', 'selectStatusFilter', 'selectCampaignSetup']),
    filteredCampaignSetups() {
      return this.selectCampaignSetups.filter(
        (campaign) =>
          !campaign.status ||
          this.selectStatusFilter === 'all' ||
          campaign.status === this.selectStatusFilter
      )
    },
  },
  methods: {
    viewSummary(campaignSetup) {
      this.$refs.campaignSetupsSummaryModal.setCampaignSetup(campaignSetup)
      this.$root.$emit('bv::show::modal', 'campaignSetupsSummaryModal')
    },
  },
}
</script>
