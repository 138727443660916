<template>
  <b-modal ref="modal" id="campaignSetupsSummaryModal" hide-footer hide-header body-class="p-0">
    <div class="modal-card card">
      <div class="card-header">
        <div v-if="campaignSetup" class="row align-items-center">
          <div class="col">
            <h4 class="card-header-title">{{ campaignSetup.title }}</h4>
          </div>
          <div class="col-auto">
            <button @click="close" type="button" class="close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" style="max-height: 650px">
        <div v-if="campaignSetup" class="row">
          <div class="col-12">
            <CampaignSetupsMessageBody :campaignSetup="campaignSetup" />
            <div class="card">
              <div class="card-body">
                <CampaignSetupsListRowUsage :campaignSetup="campaignSetup" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import CampaignSetupsListRowUsage from '@/components/Modules/CampaignSetups/Components/CampaignSetupsListRowUsage'
import CampaignSetupsMessageBody from '@/components/Modules/CampaignSetups/Components/CampaignSetupsMessageBody'

export default {
  name: 'CampaignSetupsSummaryModal',
  components: {
    CampaignSetupsListRowUsage,
    CampaignSetupsMessageBody,
  },
  data: () => ({ campaignSetup: undefined }),
  methods: {
    setCampaignSetup(campaignSetup) {
      this.campaignSetup = { ...campaignSetup }
    },
    close() {
      this.$refs.modal.hide()
    },
  },
}
</script>
