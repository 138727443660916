<template>
  <div>
    <div class="row">
      <div v-if="campaign.type === 'auto'" class="col-sm mt-2">
        <h6 class="card-title text-uppercase text-muted mb-2">SMS Usage</h6>
        <CampaignUsageBar
          :count="campaign.metrics.monthlyUsage.totalTextsSent"
          :total="textUsage.totalCount"
        />
      </div>
      <div class="col-sm mt-2">
        <h6 class="card-title text-uppercase text-muted mb-2">Recipients</h6>
        <span
          class="h2 mr-2 mb-0"
          :class="{
            'text-muted': !campaign.metrics.totalUsage.recipientsSentTo,
          }"
          >{{ campaign.metrics.totalUsage.recipientsSentTo }}</span
        >
      </div>
      <div class="col-sm mt-2">
        <h6 class="card-title text-uppercase text-muted mb-2">Redemptions</h6>
        <span class="h2 mr-2 mb-0">{{ campaign.metrics.redemptionCount }}</span>
      </div>
      <div class="col-sm mt-2">
        <h6 class="card-title text-uppercase text-muted mb-2">Opted Out</h6>
        <span class="h2 mr-2 mb-0">{{ campaign.metrics.marketingOptOutCount }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import CampaignUsageBar from './CampaignUsageBar'
import { createNamespacedHelpers } from 'vuex'
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'CampaignListRowUsage',
  props: ['campaign'],
  components: {
    CampaignUsageBar,
  },
  computed: {
    ...CompanyModule.mapState(['textUsage']),
  },
}
</script>
