<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <b-table
          :fields="fields"
          :items="filteredCampaigns"
          responsive
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :table-class="['card-table', 'table-nowrap']"
          small
        >
          <template #head()="{ label, field: { key, sortable } }">
            {{ label }}
            <template v-if="sortable">
              <template v-if="sortBy !== key">
                <i class="fe fe-arrow-down"></i>
                <i class="fe fe-arrow-up"></i>
              </template>
              <i v-else-if="sortDesc" class="fe fe-arrow-down" style="font-weight: bold"></i>
              <i v-else class="fe fe-arrow-up" style="font-weight: bold"></i>
            </template>
          </template>

          <template #cell(title)="data">
            {{ data.item.title | truncate(30) }}
          </template>

          <template #cell(status)="data">
            <span
              class="badge text-capitalize"
              style="font-size: 12px"
              :class="statusClass(data.item.status)"
            >
              {{ data.item.status }}
            </span>
          </template>

          <template #cell(schedule)="data">
            {{ schedule(data.item) }}
          </template>

          <template #cell(recipients)="data">
            {{ data.item.metrics.totalUsage.recipientsSentTo }}
          </template>

          <template #cell(metrics)="{ value }">
            {{ value.redemptionCount }}
          </template>

          <template #cell(createdat)="data">
            {{ $moment(data.item.createdAt).format('M/D/YY') }}
          </template>

          <template #cell(credits)="data">
            {{ data.item.metrics.totalUsage.totalTextsSent || '-' }}
          </template>

          <template #cell(actions)="data">
            <div class="row justify-content-end">
              <div class="col-9 text-right">
                <button
                  class="btn btn-outline-primary btn-sm btn-min-width"
                  v-if="isEditable(data.item)"
                  @click="editCampaign(data.item)"
                >
                  Edit
                </button>
                <button
                  class="btn btn-outline-primary btn-sm btn-min-width"
                  v-if="data.item.status == 'completed'"
                  @click="$emit('viewSummary', data.item)"
                >
                  Summary
                </button>
              </div>
              <div class="col-3">
                <CampaignDropdown
                  :campaign="data.item"
                  @toggle="toggle(data.item)"
                  @delete="confirmDelete(data.item)"
                  @duplicate="duplicateCampaign(data.item)"
                />
              </div>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import CampaignUsageBar from './CampaignUsageBar'
import CampaignUsage from './CampaignUsage'
import CampaignListRowHeading from './CampaignListRowHeading'
import CampaignDropdown from './CampaignDropdown'
import CampaignListRowUsage from './CampaignListRowUsage'
import CampaignService from '@/services/CampaignService'
import { createNamespacedHelpers } from 'vuex'
import { isEditableCampaign } from '@/lib/modules/campaign'
const CampaignModule = createNamespacedHelpers('campaign')
const CustomerModule = createNamespacedHelpers('customer')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'CampaignListTable',
  props: ['filteredCampaigns'],
  data: () => ({
    fields: [
      { key: 'title', sortable: true, label: 'Name', class: ['align-middle'] },
      { key: 'status', sortable: true, class: 'align-middle' },
      { key: 'schedule', class: 'align-middle' },
      { key: 'recipients', class: 'align-middle' },
      { key: 'metrics', label: 'redemptions', sortable: true, class: 'align-middle' },
      {
        key: 'redemption %',
        formatter: (value, key, item) => {
          if (item.metrics.totalUsage.recipientsSentTo > 0) {
            return `${Math.ceil(
              (item.metrics.redemptionCount / item.metrics.totalUsage.recipientsSentTo) * 100
            )}%`
          } else {
            return '0%'
          }
        },
        sortByFormatted: true,
        sortable: true,
        class: 'align-middle',
      },
      { key: 'createdAt', label: 'created', sortable: true, class: 'align-middle' },
      { key: 'credits', label: 'credits used', sortable: false, class: 'align-middle' },
      { key: 'actions', label: '', class: 'align-middle' },
    ],
    sortDesc: false,
    sortBy: 'created',
    deleteConfirmed: false,
  }),
  components: {
    CampaignListRowHeading,
    CampaignDropdown,
    CampaignListRowUsage,
    CampaignUsage,
  },
  computed: {
    ...CompanyModule.mapState(['textUsage']),
  },
  methods: {
    ...CampaignModule.mapActions(['queryCampaigns', 'deleteCampaign', 'startDuplicateCampaign']),
    ...CampaignModule.mapMutations(['startEditingCampaign']),
    ...CustomerModule.mapMutations(['setListFilters']),
    async toggle(campaign) {
      try {
        await CampaignService.toggle(campaign._id)
        this.queryCampaigns()
        this.$notify({
          title: 'Success',
          text: `Your campaign was updated.`,
        })
      } catch (err) {
        this.$notify({
          title: 'Failed to update campaign.',
          text: 'Please contact us for assistance.',
          type: 'error',
        })
      }
    },
    schedule(campaign) {
      if (campaign.status === 'draft') {
        return `Updated ${this.$moment(campaign.updatedAt).format('M/D/YY')}`
      } else if (campaign.type === 'tag') {
        return 'Indefinite'
      } else if (campaign.type === 'auto') {
        if (campaign.scheduling.autoEnd) {
          return `${
            this.$moment(campaign.scheduling.autoEndDate).isBefore(this.$moment())
              ? 'Ended'
              : 'Ends'
          } ${this.$moment(campaign.scheduling.autoEndDate).format('M/D/YY')}`
        } else {
          return 'Indefinite'
        }
      } else {
        if (campaign.scheduling.blastSchedule) {
          return `${
            this.$moment(campaign.scheduling.blastScheduleDate).isAfter(this.$moment())
              ? 'Scheduled for'
              : 'Sent'
          } ${this.$moment(campaign.scheduling.blastScheduleDate).format('M/D/YY')}`
        } else {
          if (campaign.status === 'sending') {
            return `Sending now`
          } else {
            return `Sent ${this.$moment(campaign.updatedAt).format('M/D/YY')}`
          }
        }
      }
    },
    statusClass(status) {
      if (status === 'active') {
        return 'badge-soft-success'
      } else if (status === 'paused') {
        return 'badge-soft-secondary'
      } else if (status === 'completed') {
        return 'badge-soft-primary'
      } else if (status === 'sending') {
        return 'badge-soft-info'
      } else if (status === 'scheduled') {
        return 'badge-soft-info'
      } else if (status === 'draft') {
        return 'badge-soft-warning'
      } else {
        return 'badge-soft-success'
      }
    },
    confirmDelete(campaign) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want to delete campaign "${campaign.title}"?`, {
          title: 'Please Confirm!',
          okVariant: 'danger',
          okTitle: 'Delete',
          footerClass: 'p-4',
          centered: true,
        })
        .then((value) => {
          if (value) this.deleteCampaign(campaign)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    editCampaign(campaign) {
      if (!isEditableCampaign(campaign)) {
        return
      }

      this.startEditingCampaign(campaign)
      this.setListFilters({
        ...campaign.filters,
      })
      this.$router.push('/create-campaign')
    },
    async duplicateCampaign(campaign) {

      await this.startDuplicateCampaign({ ...campaign, duplicate: true })
      this.setListFilters({
        ...campaign.filters,
      })
      this.$router.push('/create-campaign')
    },
    isEditable(campaign) {
      if (/active|paused|draft|scheduled/i.test(campaign.status)) {
        if (isEditableCampaign(campaign)) {
          return true
        }
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep tr > [aria-sort] {
  background-image: none !important;
}
.btn-min-width {
  min-width: 70px;
}
</style>
