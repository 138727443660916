<template>
  <MainContent>
    <div class="container-fluid" v-if="selectActiveCompany" :key="selectActiveCompany._id">
      <CampaignSetupsView v-if="showOrgCampaigns" />
      <CampaignList v-else />
    </div>
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import CampaignList from '@/components/Modules/Campaign/CampaignList'
import CampaignSetupsView from '@/components/Modules/CampaignSetups/CampaignSetupsView'
import { trackEvent } from '@/lib/analytics'
import { createNamespacedHelpers } from 'vuex'

const FeatureFlagModule = createNamespacedHelpers('featureFlag')
const CompanyModule = createNamespacedHelpers('company')
const OrganizationModule = createNamespacedHelpers('organization')

export default {
  name: 'CampaignsPage',
  components: {
    MainContent,
    CampaignList,
    CampaignSetupsView,
  },
  mounted() {
    trackEvent(this.$intercom, 'Campaigns')
  },
  computed: {
    ...FeatureFlagModule.mapGetters(['isEnabled']),
    ...CompanyModule.mapGetters(['selectActiveCompany']),
    ...OrganizationModule.mapGetters(['selectOrganization']),
    showOrgCampaigns() {
      return (
        (this.selectActiveCompany?._id === 'ALL_COMPANIES' && !!this.selectOrganization) ||
        (this.selectActiveCompany?._id !== 'ALL_COMPANIES' &&
          this.isEnabled('HIDE_LEGACY_CAMPAIGNS'))
      )
    },
  },
}
</script>
