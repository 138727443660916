<template>
  <div class="header">
    <div class="header-body">
      <div class="row align-items-center">
        <div class="col">
          <h6 class="header-pretitle">Take Action</h6>
          <h1 class="header-title">Campaigns</h1>
        </div>
        <div class="col-auto">
          <div class="d-flex">
            <div>
              <div class="text-muted mr-5">Remaining Credits</div>
              <h3 class="mb-0">
                {{ remainingCredits }}
              </h3>
            </div>
            <router-link to="/create-campaign" class="btn btn-primary">Create Campaign</router-link>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col">
          <ul class="nav nav-tabs nav-overflow header-tabs">
            <li class="nav-item">
              <a
                class="nav-link text-capitalize"
                :class="{ active: statusFilter == 'all' }"
                href="javascript:;"
                v-on:click="setStatusFilter('all')"
                >All
                <span class="badge badge-pill badge-soft-secondary mt--1">{{
                  this.campaigns.length
                }}</span>
              </a>
            </li>
            <li class="nav-item" v-for="(count, status) in statusFilters" :key="status">
              <a
                class="nav-link text-capitalize"
                :class="{ active: statusFilter == status }"
                href="javascript:;"
                v-on:click="setStatusFilter(status)"
                >{{ status }}
                <span class="badge badge-pill badge-soft-secondary mt--1">{{ count }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { formatNumberWithCommas } from '@/lib/format'
const CampaignModule = createNamespacedHelpers('campaign')

export default {
  name: 'CampaignListHeader',
  computed: {
    statusFilters() {
      let statuses = {}
      for (let campaign of this.campaigns) {
        campaign.status in statuses
          ? (statuses[campaign.status] = statuses[campaign.status] + 1)
          : (statuses[campaign.status] = 1)
      }
      return statuses
    },
    ...CampaignModule.mapState(['campaigns']),
    ...CampaignModule.mapState(['statusFilter']),
    ...CampaignModule.mapGetters(['selectRemainingCredits']),
    remainingCredits() {
      return this.selectRemainingCredits && this.selectRemainingCredits > 0
        ? formatNumberWithCommas(this.selectRemainingCredits)
        : 0
    },
  },
  methods: {
    ...CampaignModule.mapMutations(['setStatusFilter']),
  },
}
</script>

<style scoped lang="scss"></style>
