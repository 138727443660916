<template>
  <div>
    <CampaignSummaryModal ref="campaignSummaryModal" />
    <!-- <CampaignListRow
      :campaign="campaign"
      v-for="campaign in filteredCampaigns"
      :key="campaign._id"
      @viewSummary="viewSummary"
    /> -->
    <CampaignListTable :filteredCampaigns="filteredCampaigns" @viewSummary="viewSummary" />
  </div>
</template>

<script>
import CampaignListRow from '@/components/Modules/Campaign/CampaignListRow'
import CampaignListTable from '@/components/Modules/Campaign/CampaignListTable'
import CampaignSummaryModal from '@/components/Modals/CampaignSummaryModal'
import { createNamespacedHelpers } from 'vuex'
const CampaignModule = createNamespacedHelpers('campaign')

export default {
  name: 'CampaignListContent',
  props: ['usage'],
  components: {
    CampaignListRow,
    CampaignSummaryModal,
    CampaignListTable,
  },
  computed: {
    ...CampaignModule.mapState(['campaigns']),
    ...CampaignModule.mapState(['statusFilter']),
    filteredCampaigns() {
      let context = this
      return this.campaigns.filter(function (campaign) {
        return (
          !campaign.status ||
          context.statusFilter === 'all' ||
          campaign.status === context.statusFilter
        )
      })
    },
  },
  methods: {
    viewSummary(campaign) {
      this.$refs.campaignSummaryModal.setCampaign(campaign)
      this.$root.$emit('bv::show::modal', 'campaignSummaryModal')
    },
  },
}
</script>
