<template>
  <div>
    <div class="row">
      <div v-if="campaignSetup.type === 'auto' && selectTextUsage.totalCount" class="col-sm mt-2">
        <h6 class="card-title text-uppercase text-muted mb-2">SMS Usage</h6>
        <CampaignSetupsUsageBar :count="totalTextsSent" :total="selectTextUsage.totalCount" />
      </div>
      <div class="col-sm mt-2">
        <h6 class="card-title text-uppercase text-muted mb-2">Recipients</h6>
        <span
          class="h2 mr-2 mb-0"
          :class="{
            'text-muted': !campaignSetup.recipients,
          }"
        >
          {{ campaignSetup.recipients }}
        </span>
      </div>
      <div class="col-sm mt-2">
        <h6 class="card-title text-uppercase text-muted mb-2">Redemptions</h6>
        <span class="h2 mr-2 mb-0">{{ campaignSetup.redemptions }}</span>
      </div>
      <div class="col-sm mt-2">
        <h6 class="card-title text-uppercase text-muted mb-2">Opted Out</h6>
        <span class="h2 mr-2 mb-0">{{ marketingOptOutCount }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import CampaignSetupsUsageBar from './CampaignSetupsUsageBar'
import { arrayOfObjectsSum } from '@/lib/utils'
import { createNamespacedHelpers } from 'vuex'

const CompanyModule = createNamespacedHelpers('company')
const OrganizationModule = createNamespacedHelpers('organization')

export default {
  name: 'CampaignSetupsListRowUsage',
  props: ['campaignSetup'],
  components: {
    CampaignSetupsUsageBar,
  },
  computed: {
    ...OrganizationModule.mapGetters(['selectOrganization', 'selectTextUsage']),
    marketingOptOutCount() {
      return this.campaignSetup.campaignExecutions?.length
        ? arrayOfObjectsSum(this.campaignSetup.campaignExecutions, 'totalOptedOut')
        : 0
    },
    totalTextsSent() {
      return this.campaignSetup.campaignExecutions?.length
        ? arrayOfObjectsSum(this.campaignSetup.campaignExecutions, 'totalAttempted')
        : 0
    },
  },
}
</script>
