<template>
  <div class="card col-12">
    <div class="card-body text-center">
      <div class="row">
        <div class="col-12">
          <img
            src="/img/illustrations/rocket.png"
            alt="..."
            class="img-fluid mt--6 mb-4"
            style="max-width: 150px"
          />

          <!-- text -->
          <h2>
            {{ titleText }}
          </h2>
          <p class="text-muted mb-4">
            {{ subtitleText }}
          </p>

          <!-- button -->
          <router-link
            :to="{ name: 'create-campaign' }"
            class="btn btn-lg btn-primary"
            v-if="campaignsEnabled"
          >
            Create a Campaign
          </router-link>
          <a
            v-if="!campaignsEnabled"
            href="mailto:upgrade@ovationup.com?subject=Upgrade%20Request&body=I%20would%20like%20access%20to%20campaigns."
            class="btn btn-lg btn-primary"
          >
            Request Access
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CampaignSetupsOffstate',
  props: ['company'],
  computed: {
    titleText() {
      return this.campaignsEnabled
        ? 'Ready to automate your growth?'
        : 'Put your marketing on autopilot'
    },
    subtitleText() {
      return this.campaignsEnabled
        ? 'Send your customers the right message, at the right time.'
        : 'Upgrade your plan to enable text marketing.'
    },
    campaignsEnabled() {
      return (
        this.company._id === 'ALL_COMPANIES' ||
        (this.company._id !== 'ALL_COMPANIES' && this.company.campaignsEnabled)
      )
    },
  },
}
</script>

<style scoped lang="scss"></style>
