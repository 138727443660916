<template>
  <div class="header">
    <div class="header-body">
      <div class="row align-items-center">
        <div class="col">
          <div class="d-flex align-items-center">
            <h1 class="header-title">Campaigns</h1>
            <div
              v-if="selectActiveCompany._id === 'ALL_COMPANIES'"
              class="dropdown d-inline-block ml-4 filter-container"
            >
              <SingleSelector
                :placeholder="'Select Organization'"
                :options="selectOrganizations"
                :initial="initialOrganization"
                @update="organizationUpdate"
              />
            </div>
          </div>
        </div>
        <div class="col-auto">
          <div class="d-flex">
            <div class="mr-5">
              <div class="text-muted">Estimated Remaining Credits</div>
              <h3 class="mb-0 text-right">
                {{ creditsRemaining }}
              </h3>
            </div>
            <button class="btn btn-primary" @click="startCreation">Create Campaign</button>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col">
          <ul class="nav nav-tabs nav-overflow header-tabs">
            <li class="nav-item">
              <a
                class="nav-link text-capitalize"
                :class="{ active: selectStatusFilter == 'all' }"
                href="javascript:;"
                @click="setStatusFilter('all')"
              >
                All
                <span class="badge badge-pill badge-soft-secondary mt--1">
                  {{ this.selectCampaignSetups.length }}
                </span>
              </a>
            </li>
            <li class="nav-item" v-for="(count, status) in statusFilters" :key="status">
              <a
                class="nav-link text-capitalize"
                :class="{ active: selectStatusFilter == status }"
                href="javascript:;"
                v-on:click="setStatusFilter(status)"
              >
                {{ status }}
                <span class="badge badge-pill badge-soft-secondary mt--1">{{ count }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SingleSelector from '@/components/Modules/Filters/SingleSelectFilter_Object'
import { formatNumberWithCommas } from '@/lib/format'
import { createNamespacedHelpers } from 'vuex'

const CampaignSetupsModule = createNamespacedHelpers('campaignSetups')
const CompanyModule = createNamespacedHelpers('company')
const OrganizationModule = createNamespacedHelpers('organization')

export default {
  name: 'CampaignSetupsListHeader',
  components: { SingleSelector },
  computed: {
    ...CampaignSetupsModule.mapGetters([
      'selectCampaignSetups',
      'selectStatusFilter',
      'selectRemainingCredits',
      'selectCreditsRemaining',
    ]),
    ...CompanyModule.mapGetters(['selectActiveCompany']),
    ...OrganizationModule.mapGetters(['selectOrganizations', 'selectOrganization']),
    statusFilters() {
      let statuses = {}
      for (let campaignSetup of this.selectCampaignSetups) {
        campaignSetup.status in statuses
          ? (statuses[campaignSetup.status] = statuses[campaignSetup.status] + 1)
          : (statuses[campaignSetup.status] = 1)
      }
      return statuses
    },
    remainingCredits() {
      return this.selectRemainingCredits && this.selectRemainingCredits > 0
        ? formatNumberWithCommas(this.selectRemainingCredits)
        : 0
    },
    initialOrganization() {
      return this.selectOrganization?._id || null
    },
    creditsRemaining() {
      return this.selectCreditsRemaining > 0 ? this.selectCreditsRemaining : 0
    },
  },
  methods: {
    ...CampaignSetupsModule.mapActions([
      'setStatusFilter',
      'fetchCampaignSetups',
      'resetCampaignSetup',
      'setIsLoadingCampaignSetups',
      'fetchTextUsage',
    ]),
    ...OrganizationModule.mapActions(['setOrganization']),
    async organizationUpdate(org) {
      this.setIsLoadingCampaignSetups(true)
      await this.setOrganization(org)
      await this.fetchCampaignSetups()
    },
    async startCreation() {
      await this.resetCampaignSetup()
      this.$router.push('/create-campaign')
    },
  },
}
</script>

<style scoped lang="scss"></style>
