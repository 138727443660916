<template>
  <b-modal ref="modal" id="campaignSummaryModal" hide-footer hide-header body-class="p-0">
    <div class="modal-card card">
      <div class="card-header">
        <div v-if="campaign" class="row align-items-center">
          <div class="col">
            <h4 class="card-header-title">{{ campaign.title }}</h4>
          </div>
          <div class="col-auto">
            <button @click="close" type="button" class="close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" style="max-height: 650px">
        <div v-if="campaign" class="row">
          <div class="col-12">
            <CampaignMessageBody :campaign="campaign" />
            <div class="card">
              <div class="card-body">
                <CampaignListRowUsage :campaign="campaign" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import CampaignListRowUsage from '@/components/Modules/Campaign/CampaignListRowUsage'
import CampaignMessageBody from '@/components/Modules/Campaign/CampaignMessageBody'

export default {
  name: 'CampaignSummaryModal',
  components: {
    CampaignListRowUsage,
    CampaignMessageBody,
  },
  data: () => ({ campaign: undefined }),
  methods: {
    setCampaign(campaign) {
      this.campaign = { ...campaign }
    },
    close() {
      this.$refs.modal.hide()
    },
  },
}
</script>
