<template>
  <div class="card">
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col">
          <CampaignListRowHeading :campaign="campaign" @edit="editCampaign" />
        </div>
        <div class="col-auto" v-if="campaign.status == 'paused' || campaign.status == 'active'">
          <div class="custom-control custom-checkbox-toggle">
            <input
              class="custom-control-input"
              :id="campaign._id"
              type="checkbox"
              @change="toggle(campaign)"
              :checked="campaign.status === 'active'"
            />
            <label class="custom-control-label" :for="campaign._id"></label>
          </div>
        </div>
        <div class="col-auto">
          <CampaignListRowDropdown
            :campaign="campaign"
            @edit="editCampaign"
            @delete="confirmDelete"
            @viewSummary="$emit('viewSummary', campaign)"
          />
        </div>
      </div>
      <hr />
      <div class="row align-items-center">
        <div class="col">
          <CampaignListRowUsage :campaign="campaign" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CampaignListRowHeading from './CampaignListRowHeading'
import CampaignListRowDropdown from './CampaignListRowDropdown'
import CampaignListRowUsage from './CampaignListRowUsage'
import CampaignService from '@/services/CampaignService'
import { createNamespacedHelpers } from 'vuex'
import { isEditableCampaign } from '@/lib/modules/campaign'
const CampaignModule = createNamespacedHelpers('campaign')
const CustomerModule = createNamespacedHelpers('customer')

export default {
  name: 'CampaignListRow',
  props: ['campaign'],
  components: {
    CampaignListRowHeading,
    CampaignListRowDropdown,
    CampaignListRowUsage,
  },
  methods: {
    ...CampaignModule.mapActions(['queryCampaigns', 'deleteCampaign']),
    ...CampaignModule.mapMutations(['startEditingCampaign']),
    ...CustomerModule.mapMutations(['setListFilters']),
    async toggle() {
      try {
        await CampaignService.toggle(this.campaign._id)
        this.queryCampaigns()
        this.$notify({
          title: 'Success',
          text: `Your campaign was updated.`,
        })
      } catch (err) {
        this.$notify({
          title: 'Failed to update campaign.',
          text: 'Please contact us for assistance.',
          type: 'error',
        })
      }
    },
    confirmDelete() {
      if (confirm(`Are you sure you'd like to delete this campaign?`)) {
        this.deleteCampaign(this.campaign)
      }
    },
    editCampaign() {
      if (!isEditableCampaign(this.campaign)) {
        return
      }

      this.startEditingCampaign(this.campaign)
      this.setListFilters({
        ...this.campaign.filters,
      })
      this.$router.push('/create-campaign')
    },
  },
}
</script>
