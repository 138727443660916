<template>
  <div class="row align-items-center no-gutters">
    <div class="col-auto">
      <span class="h2 mr-2 mb-0" v-if="count">
        {{ percentage }}
        <span class="text-muted">({{ count }})</span>
      </span>
      <span class="h2 mr-2 mb-0 text-muted" v-if="!count">0</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CampaignSetupsUsageBar',
  props: ['count', 'total'],
  computed: {
    percentage() {
      return `${Math.floor((this.count / this.total) * 100)}%`
    },
  },
}
</script>
