<template>
  <FadeTransition>
    <div v-if="initialized" class="row justify-content-center">
      <div class="col-12" v-if="shouldShowCampaigns">
        <CampaignListHeader />
      </div>
      <div class="col-12" v-if="shouldShowCampaigns">
        <CampaignListContent :usage="usage" />
      </div>
      <div class="col-12 col-lg-10 mt-6" v-if="!shouldShowCampaigns">
        <CampaignOffstate :company="activeCompany" />
      </div>
    </div>
  </FadeTransition>
</template>

<script>
import FadeTransition from '@/components/Transitions/FadeTransition'
import CampaignListHeader from '@/components/Modules/Campaign/CampaignListHeader'
import CampaignListContent from '@/components/Modules/Campaign/CampaignListContent'
import CampaignOffstate from '@/components/Modules/Campaign/CampaignOffstate'
import { createNamespacedHelpers } from 'vuex'
const CampaignModule = createNamespacedHelpers('campaign')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'CampaignsList',
  components: {
    FadeTransition,
    CampaignListHeader,
    CampaignListContent,
    CampaignOffstate,
  },
  data: () => ({
    usage: undefined,
    initialized: false,
  }),
  computed: {
    ...CompanyModule.mapState(['activeCompany']),
    ...CampaignModule.mapState(['campaigns']),
    shouldShowCampaigns() {
      return (
        this.campaigns &&
        this.campaigns.length &&
        this.activeCompany &&
        this.activeCompany.campaignsEnabled
      )
    },
  },
  watch: {
    activeCompany: {
      immediate: true,
      async handler() {
        await Promise.all([
          this.queryCampaigns(),
          this.queryTextUsage(),
          this.fetchRemainingCredits(),
        ])
        this.initialized = true
      },
    },
  },
  methods: {
    ...CompanyModule.mapActions(['queryTextUsage']),
    ...CampaignModule.mapActions(['queryCampaigns', 'fetchRemainingCredits']),
  },
}
</script>
