<template>
  <div class="card">
    <div class="card-body">
      <h6 class="header-pretitle mb-3">Your Message</h6>
      <div class="message">
        <div class="comment-body">
          <p class="comment-text mt-0" style="white-space: pre-line" v-html="parsedMessageBody"></p>
          <p v-if="parsedPromo" v-html="parsedPromo"></p>
          Sent from {{ name }} <br />Reply STOP to opt-out
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { templatize } from '@/lib/template'
import { createNamespacedHelpers } from 'vuex'

const CompanyModule = createNamespacedHelpers('company')
const OrganizationModule = createNamespacedHelpers('organization')

export default {
  name: 'CampaignSetupsMessageBody',
  props: ['campaignSetup'],
  computed: {
    ...CompanyModule.mapGetters(['selectActiveCompany']),
    ...OrganizationModule.mapGetters(['selectOrganization']),
    parsedMessageBody() {
      return templatize({
        body: this.campaignSetupMessage,
        customer: { name: 'Jane Doe' },
      })
    },
    campaignSetupMessage() {
      return this.campaignSetup.templateChunks.find((tc) => tc.type === 'text').template
    },
    parsedPromo() {
      if (!this.campaignSetup.promo) return undefined
      return 'Redeem: <span class="text-primary">ovtn.io/redeem</span>'
    },
    name() {
      return this.selectActiveCompany._id === 'ALL_COMPANIES'
        ? this.selectOrganization?.name
        : this.selectActiveCompany.name
    },
  },
}
</script>

<style scoped lang="scss">
.message .comment-text:after {
  display: none;
}
</style>
