<template>
  <div>
    <!-- Title -->
    <h6 class="card-title text-uppercase text-muted mb-2">{{ subheading }}</h6>

    <!-- Heading -->
    <span @click="$emit('edit')" class="h2 mb-0 text-capitalize" :class="campaign.type">{{
      campaign.title
    }}</span>

    <!-- Badge -->
    <span class="badge mt--2 ml-2 text-capitalize" :class="statusClass">{{ campaign.status }}</span>
  </div>
</template>

<script>
export default {
  name: 'CampaignListRowHeading',
  props: ['campaign'],
  computed: {
    subheading() {
      if (this.campaign.status === 'draft') {
        return `Updated on ${this.$moment(this.campaign.updatedAt).format('MMM Do, YYYY')}`
      } else if (this.campaign.type === 'tag') {
        return 'Indefinite'
      } else if (this.campaign.type === 'auto') {
        if (this.campaign.scheduling.autoEnd) {
          return `${
            this.$moment(this.campaign.scheduling.autoEndDate).isBefore(this.$moment())
              ? 'Ended'
              : 'Ends'
          } on ${this.$moment(this.campaign.scheduling.autoEndDate).format('MMM Do, YYYY')}`
        } else {
          return 'Indefinite'
        }
      } else {
        if (this.campaign.scheduling.blastSchedule) {
          return `${
            this.$moment(this.campaign.scheduling.blastScheduleDate).isAfter(this.$moment())
              ? 'Scheduled for'
              : 'Sent on'
          } ${this.$moment(this.campaign.scheduling.blastScheduleDate).format('MMM Do, YYYY')}`
        } else {
          if (this.campaign.status === 'sending') {
            return `Sending right now`
          } else {
            return `Sent on ${this.$moment(this.campaign.updatedAt).format('MMM Do, YYYY')}`
          }
        }
      }
    },
    statusClass() {
      if (this.campaign.status === 'active') {
        return 'badge-soft-success'
      } else if (this.campaign.status === 'paused') {
        return 'badge-soft-secondary'
      } else if (this.campaign.status === 'completed') {
        return 'badge-soft-primary'
      } else if (this.campaign.status === 'sending') {
        return 'badge-soft-info'
      } else if (this.campaign.status === 'scheduled') {
        return 'badge-soft-info'
      } else if (this.campaign.status === 'draft') {
        return 'badge-soft-warning'
      } else {
        return 'badge-soft-success'
      }
    },
  },
}
</script>

<style scoped lang="scss">
.h2.auto:hover {
  cursor: pointer;
}
</style>
