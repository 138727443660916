<template>
  <div class="row align-items-center no-gutters">
    <div class="col-auto">
      <span class="h2 mr-2 mb-0" v-if="count">
        {{ percentage }}
        <span class="text-muted">({{ count }})</span>
      </span>
      <span class="h2 mr-2 mb-0 text-muted" v-if="!count">0</span>
    </div>
    <!-- <div class="col">
      <div class="progress progress-sm">
        <div class="progress-bar" role="progressbar" :style="progressStyles"></div>
      </div>
    </div>-->
  </div>
</template>

<script>
export default {
  name: 'CampaignUsageBar',
  props: ['count', 'total'],
  computed: {
    percentage() {
      return `${Math.floor((this.count / this.total) * 100)}%`
    },
    // progressStyles() {
    //   return {
    //     width: `${this.count}%`
    //   }
    // }
  },
}
</script>

<style scoped lang="scss"></style>
