<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <b-table
          :fields="fields"
          :items="formattedFilteredCampaignSetups"
          responsive
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :table-class="['card-table', 'table-nowrap']"
          small
        >
          <template #head()="{ label, field: { key, sortable } }">
            {{ label }}
            <template v-if="sortable">
              <template v-if="sortBy !== key">
                <i class="fe fe-arrow-down"></i>
                <i class="fe fe-arrow-up"></i>
              </template>
              <i v-else-if="sortDesc" class="fe fe-arrow-down" style="font-weight: bold"></i>
              <i v-else class="fe fe-arrow-up" style="font-weight: bold"></i>
            </template>
          </template>

          <template #cell(title)="data">
            {{ data.item.title | truncate(30) }}
          </template>

          <template #cell(status)="data">
            <span
              class="badge text-capitalize"
              style="font-size: 12px"
              :class="statusClass(data.item.status)"
            >
              {{ data.item.status }}
            </span>
          </template>

          <template #cell(schedule)="data">
            {{ schedule(data.item) }}
          </template>

          <template #cell(recipients)="data">
            {{ data.item.recipients }}
          </template>

          <template #cell(redemptions)="data">
            {{ data.item.redemptions }}
          </template>

          <template #cell(createdAt)="data">
            {{ $moment(data.item.createdAt).format('M/D/YY') }}
          </template>

          <template #cell(credits)="data">
            {{ creditsUsed(data.item) }}
          </template>

          <template #cell(actions)="data">
            <div class="d-flex justify-content-end align-items-center">
              <div>
                <button
                  class="btn btn-outline-primary btn-sm btn-min-width"
                  v-if="isEditable(data.item)"
                  @click="editCampaign(data.item)"
                >
                  Edit
                </button>
                <button
                  class="btn btn-outline-primary btn-sm btn-min-width"
                  v-if="data.item.status == 'completed'"
                  @click="$emit('viewSummary', data.item)"
                >
                  Summary
                </button>
              </div>
              <div class="ml-3">
                <CampaignSetupsListRowDropdown
                  :campaignSetup="data.item"
                  @toggle="toggle(data.item)"
                  @delete="confirmDelete(data.item)"
                  @duplicate="duplicateCampaign(data.item)"
                />
              </div>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import CampaignSetupsListRowDropdown from './CampaignSetupsListRowDropdown'
import { isEditableCampaignSetup } from '@/lib/modules/campaignSetups'
import { arrayOfObjectsSum } from '@/lib/utils'
import { createNamespacedHelpers } from 'vuex'

const CampaignSetupsModule = createNamespacedHelpers('campaignSetups')

export default {
  name: 'CampaignSetupsListTable',
  props: ['filteredCampaignSetups'],
  components: {
    CampaignSetupsListRowDropdown,
  },
  data: () => ({
    fields: [
      { key: 'title', sortable: true, label: 'Name', class: ['align-middle'] },
      { key: 'status', sortable: true, class: 'align-middle' },
      { key: 'schedule', class: 'align-middle' },
      { key: 'recipients', sortable: true, class: 'align-middle' },
      { key: 'redemptions', sortable: true, class: 'align-middle' },
      { key: 'redemptionPercentage', label: 'redemption %', sortable: true, class: 'align-middle' },
      { key: 'createdAt', label: 'created', sortable: true, class: 'align-middle' },
      { key: 'credits', label: 'credits used', sortable: false, class: 'align-middle' },
      { key: 'actions', label: '', class: 'align-middle' },
    ],
    sortDesc: false,
    sortBy: 'created',
    deleteConfirmed: false,
  }),
  computed: {
    formattedFilteredCampaignSetups() {
      return this.filteredCampaignSetups.reduce((acc, curr) => {
        curr.recipients = arrayOfObjectsSum(curr.campaignExecutions, 'totalAttempted')
        curr.redemptions = arrayOfObjectsSum(curr.campaignExecutions, 'totalRedeemed')
        curr.redemptionPercentage =
          curr.recipients > 0 ? `${Math.ceil((curr.redemptions / curr.recipients) * 100)}%` : '0%'
        acc.push(curr)
        return acc
      }, [])
    },
  },
  methods: {
    ...CampaignSetupsModule.mapActions([
      'fetchCampaignSetups',
      'startEditingCampaignSetup',
      'deleteCampaignSetup',
      'updateCampaignSetup',
      'startDuplicatingCampaignSetup',
    ]),
    creditsUsed(campaignSetup) {
      return (
        campaignSetup.campaignExecutions.reduce(
          (acc, curr) => (acc += curr.totalAttempted * (curr.segmentCount || 1)),
          0
        ) || 0
      )
    },
    schedule(campaignSetup) {
      if (campaignSetup.status === 'draft') {
        return `Updated ${this.$moment(campaignSetup.updatedAt).format('M/D/YY')}`
      } else if (campaignSetup.type === 'tag') {
        return 'Indefinite'
      } else if (campaignSetup.type === 'auto') {
        if (campaignSetup.autoSettings.endDate) {
          return `${
            this.$moment(campaignSetup.autoSettings.endDate).isBefore(this.$moment())
              ? 'Ended'
              : 'Ends'
          } ${this.$moment(campaignSetup.autoSettings.endDate).format('M/D/YY')}`
        } else {
          return 'Indefinite'
        }
      } else if (campaignSetup.type === 'blast') {
        return `${
          this.$moment(campaignSetup.startDate).isAfter(this.$moment()) ||
          campaignSetup.status === 'initialized'
            ? 'Scheduled for'
            : 'Sent'
        } ${this.$moment(campaignSetup.startDate).format('M/D/YY')}`
      } else if (campaignSetup.status === 'sending') {
        return `Sending now`
      } else {
        return `Sent ${this.$moment(campaignSetup.updatedAt).format('M/D/YY')}`
      }
    },
    statusClass(status) {
      if (status === 'active') {
        return 'badge-soft-success'
      } else if (status === 'disabled') {
        return 'badge-soft-secondary'
      } else if (status === 'completed') {
        return 'badge-soft-primary'
      } else if (status === 'sending') {
        return 'badge-soft-info'
      } else if (status === 'initialized') {
        return 'badge-soft-info'
      } else if (status === 'draft') {
        return 'badge-soft-warning'
      } else {
        return 'badge-soft-success'
      }
    },
    async toggle(campaignSetup) {
      try {
        await this.updateCampaignSetup({
          campaignSetupId: campaignSetup._id,
          campaignSetup: {
            ...campaignSetup,
            status: campaignSetup.status === 'disabled' ? 'active' : 'disabled',
          },
        })
        this.fetchCampaignSetups()
        this.$notify({
          title: 'Success',
          text: `Your campaign was updated.`,
        })
      } catch (err) {
        this.$notify({
          title: 'Failed to update campaign.',
          text: 'Please contact us for assistance.',
          type: 'error',
        })
      }
    },
    async confirmDelete(campaignSetup) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want to delete campaign "${campaignSetup.title}"?`, {
          title: 'Please Confirm!',
          okVariant: 'danger',
          okTitle: 'Delete',
          footerClass: 'p-4',
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            await this.deleteCampaignSetup(campaignSetup._id)
            this.fetchCampaignSetups()
          }
        })
        .catch((err) => {
          console.log(err)
          this.$notify({ text: 'Failed to delete campaign', type: 'error' })
        })
    },
    editCampaign(campaignSetup) {
      if (!isEditableCampaignSetup(campaignSetup)) {
        return
      }
      this.startEditingCampaignSetup(campaignSetup)
      this.$router.push('/create-campaign')
    },
    async duplicateCampaign(campaignSetup) {
      this.startDuplicatingCampaignSetup(campaignSetup)
      this.$router.push('/create-campaign')
    },
    isEditable(campaignSetup) {
      if (/active|paused|draft|initialized/i.test(campaignSetup.status)) {
        if (isEditableCampaignSetup(campaignSetup)) {
          return true
        }
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep tr > [aria-sort] {
  background-image: none !important;
}
.btn-min-width {
  min-width: 70px;
}
</style>
